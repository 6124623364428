import React from 'react'
import styled from '@emotion/styled'
import { BlockMonogram, BDCLogo } from '@jelly/components'
import { Link } from '@jelly/ui'
import { useTheme } from 'emotion-theming'

const FooterWrapper = styled.div`
	background-color: rgb(0, 59, 92);
	color: ${({ theme }) => theme.colors.white};
`

const FooterContainer = styled.div`
	display: flex;
	flex-direction: column;
	font-size: 0.7rem;
	margin: 0 auto;
	max-width: 60rem;
	padding: 2rem 1rem;
	${({ theme }) => theme.MQ.L} {
		font-size: 0.875rem;
		padding: 4rem 1rem;
	}
`

const BlockMonogramContainer = styled.span`
	height: 4.5rem;
	margin-bottom: 1rem;
	margin-top: 1rem;
	width: 3.5rem;
`
const BDCLogoContainer = styled.span`
	width: 13rem;
`

const ContactContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 1rem;

	span,
	a {
		margin-bottom: 0.5rem;
	}
`
const LinksContainer = styled.div`
	display: flex;
	margin-bottom: 1rem;
	margin-top: 0.5rem;
`

const Footer = () => {
	const {
		colors: { secondary },
	} = useTheme()
	return (
		<FooterWrapper>
			<FooterContainer>
				<BlockMonogramContainer>
					<BlockMonogram color={secondary} width="100%" height="100%" />
				</BlockMonogramContainer>
				<BDCLogoContainer>
					<BDCLogo width="100%" height="100%" />
				</BDCLogoContainer>
				<ContactContainer>
					<Link href="tel:1-888-393-5000" variant="light">
						888.393.5000
					</Link>{' '}
					<Link href="mailto:connect@business.com" variant="light">
						connect@business.com
					</Link>{' '}
					<span>200 5th Ave, 2nd Floor</span>
					<span>Waltham, MA 02451</span>
				</ContactContainer>

				<LinksContainer>
					<span>
						<Link href="/terms-of-use/" variant="light">
							Terms & Conditions
						</Link>{' '}
						{' | '}
						<Link href="/privacy-policy/" variant="light">
							Privacy Policy
						</Link>{' '}
						{' | '}
						<Link href="/optout/" variant="light">
							Do Not Sell My Personal Information
						</Link>{' '}
						{' | '}
						<Link href="/accessibility/" variant="light">
							Accessibility
						</Link>{' '}
					</span>
				</LinksContainer>

				<span>&copy;{new Date().getFullYear()} business.com. All Rights Reserved.</span>
			</FooterContainer>
		</FooterWrapper>
	)
}

export default Footer
