import React from 'react'
import styled from '@emotion/styled'
import { BlockBDCNavy } from '@jelly/components'
import { Link } from '@jelly/ui'

import wideformatprinter from '../../../../images/lp/wide-format-printers-plotters/wide-format-printer.jpeg'

const LogoWrapper = styled.nav`
	margin: 0 auto;
	max-width: 60rem;
	padding: 0.5rem 0 0.1rem 0;
	text-align: center;
	width: 9rem;
`

const HeaderWrapper = styled.header`
	background: url(${wideformatprinter});
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;

	color: ${({ theme }) => theme.colors.white};
	min-height: 176px;
	margin: 0 auto;
	max-width: 100%;
	position: relative;
	text-align: center;
	z-index: 1;

	h1 {
		font-size: 1rem;
		line-height: 1.1rem;
		margin: 0;
		position: relative;
		padding: 16px 35px 0;
		z-index: 2;
	}

	p {
		font-size: 0.8rem;
		line-height: 0.8rem;
		margin: 0;
		position: relative;
		padding: 5px 15px;
		word-wrap: break-word;
		z-index: 2;
	}

	${({ theme }) => theme.MQ.S} {
		min-height: 13rem;

		h1 {
			font-size: 1.2rem;
			line-height: 1.5rem;
			padding: 16px 40px 0;
		}
		p {
			font-size: 0.8rem;
			line-height: 0.9rem;
		}
	}

	${({ theme }) => theme.MQ.M} {
		min-height: 14rem;

		h1 {
			font-size: 1.7rem;
			line-height: 2.1rem;
		}
		p {
			font-size: 1rem;
			line-height: 1.1rem;
		}
	}
`

const HeaderContainer = styled.div`
	margin: 0 auto;
	max-width: 23rem;

	${({ theme }) => theme.MQ.S} {
		max-width: 27rem;
	}

	${({ theme }) => theme.MQ.M} {
		max-width: 40rem;
	}

	${({ theme }) => theme.MQ.L} {
		max-width: 50rem;
	}
`

const HeaderOverlay = styled.div`
	background-color: #003b5c;
	height: 100%;
	left: 0;
	opacity: 0.55;
	position: absolute;
	top: 0;
	width: 100%;
`

const Header = () => {
	return (
		<>
			<LogoWrapper>
				<Link href="/">
					<BlockBDCNavy />
				</Link>
			</LogoWrapper>
			<HeaderWrapper>
				<HeaderContainer>
					<h1>Compare Price Quotes for Wide Format Printers and Plotters and Save</h1>
					<p>
						Get customized price quotes from multiple wide format printer and plotter dealers so you know
						you're getting the best deal.
					</p>
				</HeaderContainer>
				<HeaderOverlay />
			</HeaderWrapper>
		</>
	)
}

export default Header
